<template>
  <b-sidebar
    v-model="isSidebarOpen"
    type="is-light"
    class="module-info-sidebar"
    :fullheight="true"
    :right="true"
    :can-cancel="['escape', 'outside']"
  >
    <div>
      <p
        class="level-item"
      >
        <strong
          class="has-text-centered module-info-sidebar-heading"
        >{{ moduleInfo.name }}</strong>
      </p>
      <div class="sidebar-options has-text-centered">
        <p class="level-item">
          <strong>Created At</strong>
        </p>
        {{ moduleInfo.created_at }}
        <b-tooltip
          size="is-small" 
          :label="moduleInfo.author"
        >
          <b-button
            size="is-small" 
            class="button disk disk-small is-primary"
          >
            {{ moduleInfo.initials }}
          </b-button>
        </b-tooltip>
      </div>

      <div
        v-if="moduleInfo.prod_change_log"
        class="sidebar-options"
      >
        <p class="level-item">
          <strong>Prod change log</strong>
        </p>
        <p
          class="has-text-centered"
          v-html="moduleInfo.prod_change_log"
        />
      </div>
      <div
        v-if="moduleInfo.deployed_prod_id && moduleInfo.deployed_prod_url"
        class="sidebar-options"
      >
        <div
          class="is-flex"
        >
          <p
            class="level-item"
          >
            <a
              :href="moduleInfo.deployed_prod_url"
              target="_blank"
              rel="noreferrer"
            >Production URL</a>
          </p>
          <div
            class="cursor-pointer"
            @click="copyToClipboard(moduleInfo.deployed_prod_url, 'production url.')"
          >
            <b-icon
              icon="content-copy"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
          </div>
        </div>
      </div>
      <div
        v-if="moduleInfo.test_change_log"
        class="sidebar-options"
      >
        <p class="level-item">
          <strong>Test change log</strong>
        </p>
        <p
          class="has-text-centered"
          v-html="moduleInfo.test_change_log"
        />
      </div>
      <div
        v-if="moduleInfo.deployed_test_id && moduleInfo.deployed_test_url"
        class="sidebar-options"
      >
        <div
          class="is-flex"
        >
          <p
            class="level-item"
          >
            <a
              :href="moduleInfo.deployed_test_url"
              target="_blank"
              rel="noreferrer"
            >Test URL</a>
          </p>
          <div
            class="cursor-pointer"
            @click="copyToClipboard(moduleInfo.deployed_test_url, 'test url.')"
          >
            <b-icon
              icon="content-copy"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
          </div>
        </div>
      </div>
      <div>
        <BaseSelect
          v-if="isWebApp"
          label="Auth Module"
          class="has-text-centered"
          :value="moduleInfo.guarded_module_id"
          @input="selectAuthModule($event)"
        >
          <option
            class="has-text-centered"
            :value="null"
          >
            -- No Auth --
          </option>
          <option
            v-for="(authModule, idx) in authModules"
            :key="idx+'-'+authModule.id"
            :value="authModule.id"
            class="has-text-centered"
          >
            {{ authModule.name }}
          </option>
        </BaseSelect>
      </div>
    </div>
  </b-sidebar>
</template>

<script >
// lib
import { computed, onMounted, ref } from '@vue/composition-api';
// components
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
// services
import { guardModuleService, unguardModuleService } from '@/services/application-service/moduleRequests';
// composables
import { useBuefy } from '@/hooks/buefy';
import { useRoute } from '@/hooks/vueRouter';
import { getAuthModuleListService } from '@/services/application-service/module-auth/authModuleRequests';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  },
  moduleInfo: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  //-- use composables --//
  const buefy = useBuefy();
  const route = useRoute();
  const authModules = ref([]);
  const isSidebarOpen = computed({
    get() {
      return props.value;
    },
    set(val) {
      emit('input', val);
    }
  });
  const isWebApp = computed(() => props.moduleInfo?.type === 'Web app/Screens');
  const fetchAuthModules = async () => {
    try {
      const authModule = await getAuthModuleListService(route.params.appId);
      authModules.value = authModule.data.data;
    } catch (err) {
      console.error(err);
    }
  };
  onMounted(() => {
    fetchAuthModules();
  });
  const copyToClipboard = async (text, message) => {
    try {
      await navigator.clipboard.writeText(text);
      buefy.toast.open({
        message: `Copied ${message}`,
        type: 'is-success'
      });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  const selectAuthModule = async authModuleId => {
    const {
      id: moduleId,
      guarded_module_id: existingAuthModuleId
    } = props.moduleInfo;
    if (authModuleId) {
      // handle guarding module
      await guardModuleService(route.params.appId, moduleId, {
        guarded_module_id: authModuleId
      });
      emit('guard-module', {
        moduleId,
        authModuleId
      });
    } else {
      // handle unguarding module
      await unguardModuleService(route.params.appId, moduleId, {
        guarded_module_id: existingAuthModuleId
      });
      emit('unguard-module', {
        moduleId
      });
    }
  };
  return {
    authModules,
    isSidebarOpen,
    isWebApp,
    copyToClipboard,
    selectAuthModule
  };
};
__sfc_main.components = Object.assign({
  BaseSelect
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
.module-info-sidebar {
  .sidebar-content {
    z-index: 50;
  }
}
.module-info-sidebar-heading {
  color: $primary;
}
.b-sidebar{
  .level-item {
    width: 90%;
  }
}
.sidebar-options {
  padding: 0.375rem 0.625rem;
  border: 0.125rem solid rgba(black, 0.1);
  margin: 0.625rem auto;
}
</style>
