// @ts-check
import { FORM_BUILDER_STORE_ID } from '@/constants/storeIds';
import { reactive } from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useFormBuilderStore = defineStore(FORM_BUILDER_STORE_ID, () => {
    /**
     * Initial state of formula
     * @type {import('./types/formBuilder').IFormula}
     */
    const initialFormula = {
        error: false,
        message: '',
        value: ''
    };
    let formula = reactive({ ...initialFormula });
    /**
     * @param {import('./types/formBuilder').IFormula} formulaPayload 
     */
    const setFormula = (formulaPayload) => {
        if (typeof formulaPayload.error !== 'undefined') {
            formula.error = formulaPayload.error;
        }
        if (typeof formulaPayload.message !== 'undefined') {
            formula.message = formulaPayload.message;
        }
        if (typeof formulaPayload.value !== 'undefined') {
            formula.value = formulaPayload.value;
        }
    };
    
    const reset = () => {
        formula = Object.assign(formula, initialFormula);   
    };

    return {
        formula,
        setFormula,
        reset
    };
});
