// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH, MODULE_RESOURCE_PATH, AUTH_MODULE_RESOURCE_PATH, GUARD_MODULE_RESOURCE_PATH } from './../applicationServiceConstants';

export const authModuleServiceConfig = {
    ...createHttpServiceConfig(APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH),
    /**
     * @param {string} applicationId 
     * @param {string} [moduleId] 
     */
    getModuleResourcePath: (applicationId, moduleId = '') => `/${applicationId}${MODULE_RESOURCE_PATH}${moduleId ? '/' : ''}${moduleId}`
};

//-- request interceptors --//
authModuleServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {object} data
 */
export const addAuthModuleCustomNodeService = (applicationId, moduleId, data) => authModuleServiceConfig.http({
    method: 'POST',
    url: `${authModuleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${AUTH_MODULE_RESOURCE_PATH}`,
    data
});

/**
 * @param {string} applicationId
 */
export const getAuthModuleListService = (applicationId) => authModuleServiceConfig.http({
    method: 'GET',
    url: `${applicationId}${AUTH_MODULE_RESOURCE_PATH}`
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {object} data
 */
export const removeAuthModuleAuthNodeService = (applicationId, moduleId, data) => authModuleServiceConfig.http({
    method: 'DELETE',
    url: `${authModuleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${AUTH_MODULE_RESOURCE_PATH}`,
    data
});