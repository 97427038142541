// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { BUILD_SERVICE_ENDPOINT, DEPLOYMENT_RESOURCE_PATH } from './buildServiceConstants';

const buildServiceConfig = createHttpServiceConfig(BUILD_SERVICE_ENDPOINT, DEPLOYMENT_RESOURCE_PATH);

//-- request interceptors --//
buildServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {import('./buildServiceTypes').IFetchDeploymentsParams} params 
 */
export const fetchDeploymentsService = (params) => buildServiceConfig.http({
    method: 'GET',
    params
});

/**
 * @param {import('../../types/buildTypes').IDeployment} data 
 */
export const createDeploymentService = (data) => buildServiceConfig.http({
    method: 'POST',
    data
});
