import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';

import { WORKFLOW_STORE_ID } from '@/constants/storeIds';
import { fetchWorkflowsService } from '@/services/application-service/workflowRequests';
import { useModuleTable } from '@/modules/builder/components/shared/module-list-blocks/moduleTable';

export const useWorkflowStore = defineStore(WORKFLOW_STORE_ID, () => {
    const areWorkflowsLoading = ref(false);
    const workflows = ref([]);
    const noOfWorkflows = ref(0);

    const { transformTableData } = useModuleTable();

    const fetchWorkflows = async (appId, moduleId) => {
        try {
            if(!appId || !moduleId) return;
            areWorkflowsLoading.value = true;
            let { data: { data } } = await fetchWorkflowsService(appId, moduleId);
            noOfWorkflows.value = data?.length || 0;
            workflows.value = transformTableData(data);
        } catch (err) {
            console.error(err);
        } finally {
            areWorkflowsLoading.value = false;
        }
    };

    return {
        areWorkflowsLoading,
        workflows,
        fetchWorkflows,
        noOfWorkflows
    };
});