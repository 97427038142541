<template>
  <ConfirmDeleteModal
    v-if="hasModuleInfoLoaded"
    v-model="isDeleteModuleModalVisible"
    :entity-name="moduleInfo.data.name"
    :has-modal-card="true"
    :trap-focus="true"
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Confirm Delete Module"
    aria-modal
    @delete="handleDeletingModule()"
  >
    <template
      v-if="moduleInfo.dependency.guardedModules.length > 0"
      #additional-message
    >
      <div
        class="is-flex is-align-items-center"
      >
        <b-icon
          size="is-medium"
          icon="alert-circle"
          type="is-danger"
          class="mr-4"
        />
        <div>
          <p class="is-size-6">
            Please note that the following modules are guarded by the module you are about to delete:
          </p>
          <ul style="list-style-type: disc; margin-left: 20px">
            <li
              v-for="guardedModule in moduleInfo.dependency.guardedModules"
              :key="guardedModule.id"
            >
              <strong>{{ guardedModule.name }}</strong>
            </li>
          </ul>
          <p>If you delete <strong>{{ moduleInfo.data.name }}</strong>, the modules stated above will not be guarded anymore.</p>
        </div>
      </div>
    </template>
  </ConfirmDeleteModal>
</template>

<script >
import { computed } from '@vue/composition-api';
import ConfirmDeleteModal from '@/modules/core/components/ConfirmDeleteModal.vue';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  },
  moduleInfo: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const hasModuleInfoLoaded = computed(() => props.moduleInfo && Object.entries(props.moduleInfo).length > 0);
  const isDeleteModuleModalVisible = computed({
    get() {
      return props.value;
    },
    set(val) {
      emit('input', val);
    }
  });
  const handleDeletingModule = () => {
    emit('delete');
  };
  return {
    hasModuleInfoLoaded,
    isDeleteModuleModalVisible,
    handleDeletingModule
  };
};
__sfc_main.components = Object.assign({
  ConfirmDeleteModal
}, __sfc_main.components);
export default __sfc_main;
</script>
