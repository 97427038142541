// @ts-check
import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { APPLICATION_STORE_ID } from '@/constants/storeIds';

export const useApplicationStore = defineStore(APPLICATION_STORE_ID, () => { 
    /**
     * @type {import('@vue/composition-api').Ref<string | null>}
     */
    const name = ref(null);
    /**
     * @param {string} newName
     */
    const setName = (newName) => {
        name.value = newName;
    };

    /**
     * @type {import('@vue/composition-api').Ref<string | null>}
     */
    const applicationId = ref(null);
    /** 
     * @param {string} id 
     */
    const setApplicationId = (id) => {
        applicationId.value = id;
    };

    const reset = () => {
        name.value = null;
        applicationId.value = null;
    };

    return {
        name,
        setName,
        applicationId,
        setApplicationId,
        reset
    };
});
