import { ref } from '@vue/composition-api';
import { convertUTCToLocalTime, getFirstLetters } from '@/helpers/util';
import { useModuleDeployStore } from '@/modules/builder/store/moduleDeployStore';

const tableActions = ref({
    INFO: 'info',
    CREATE: 'create',
    EDIT: 'edit',
    OPEN_VERSIONS: 'open versions',
    OPEN_ANALYTICS: 'open analytics',
    DUPLICATE: 'duplicate',
    DELETE: 'delete'
});

export const useModuleTable = () => {
    const moduleDeployStore = useModuleDeployStore();

    const transformTableData = (modules) => {
        const tableData = modules.map(module => {
            // @TODO's: Check if all this data processing can be done on backend side while fetching data
            if (module.deployed_env === 'test') {
                module.deployed_prod_id = module.prev_deployment_id;
                module.deployed_test_id = module.deployment_id;

                module.test_version = module.last_deployed_version;
                module.prod_version = module.prev_deployed_version;

                module.test_change_log = module.change_log;
                module.prod_change_log = module.prev_change_log;
            } else if (module.deployed_env === 'production'){
                module.deployed_prod_id = module.deployment_id;
                module.deployed_test_id = module.prev_deployment_id;

                module.test_version = module.prev_deployed_version;
                module.prod_version = module.last_deployed_version;

                module.test_change_log = module.prev_change_log;
                module.prod_change_log = module.change_log;
            } else {
                module.deployed_test_id = null;
                module.deployed_prod_id = null;
                module.test_version = null;
                module.prod_version = null;
                module.test_change_log = null;
                module.prod_change_log = null;
            }
            return {
                ...module,
                created_at: convertUTCToLocalTime(module.created_at),
                updated_at: convertUTCToLocalTime(module.updated_at),
                last_editor_initials: getFirstLetters(module.last_editor),
                deployed_env: module.deployed_env? module.deployed_env.toUpperCase(): 'N/A',
                last_deployed_version: module.last_deployed_version? module.last_deployed_version: 'N/A',
                deployed_at: module.deployed_at? convertUTCToLocalTime(module.deployed_at): 'N/A',
                test_version: module.test_version? convertUTCToLocalTime(module.test_version, undefined, 'DD.MM.YYYY HH:mm'): 'N/A',
                prod_version: module.prod_version? convertUTCToLocalTime(module.prod_version, undefined, 'DD.MM.YYYY HH:mm'): 'N/A',
                deployed_prod_url: moduleDeployStore.deploymentUrls[module.moduleTypeId] ? moduleDeployStore.deploymentUrls[module.moduleTypeId] + module.deployed_prod_id : null,
                deployed_test_url: moduleDeployStore.deploymentUrls[module.moduleTypeId] ? moduleDeployStore.deploymentUrls[module.moduleTypeId] + module.deployed_test_id : null,
            };
        });

        return tableData;
    };

    return {
        tableActions,
        transformTableData
    };
};
