<template>
  <div>
    <teleport selector="#appButton">
      <b-button
        class="px-6 add-new-btn btn-primary-light"
        @click="setNewModuleModalVisibility(true)"
      >
        <b-icon
          icon="plus"
          size="is-small"
        />New module
      </b-button>
    </teleport>
    <ModuleList 
      @create="setNewModuleModalVisibility(true)"
    />
    <BaseModal
      v-model="isNewModuleModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New module"
      aria-modal
    >
      <CardPopup
        title="New module"
        class="w-800"
        @hide="setNewModuleModalVisibility(false)"
      >
        <template #body>
          <b-notification
            v-if="isModuleCreationFailed"
            :closable="false"
            size="is-small"
            type="is-danger"
          >
            Please select a module type
          </b-notification>
          <b-field
            label="Module name*"
            :type="moduleErrors.name ? 'is-danger' : ''"
            :message="moduleErrors.name ? moduleErrors.name : ''"
          >
            <b-input
              v-model="module.name"
              type="text"
              placeholder="e.g. Customer portal"
              @keyup.native="validateModule('name')"
            />
          </b-field>
          <label class="label my-2">Select a type of module you wish to build:</label>
          <div
            v-for="(type, index) in options.types"
            :key="index"
            class="media pt-3 pb-0 px-3 selection-list"
            :class="type.id === 4 ? 'disabled' : module.id ? type.id === module.id? 'selected' : 'unselected' : ''"
            @click="selectType(type.name, type.id)"
          >
            <b-icon
              class="media-left mt-2"
              :icon="type.icon"
              size="is-medium"
            />
            <div class="media-content">
              <h5 class="is-size-5">
                {{ type.name }}
                <b-tag
                  v-if="type.id === 4"
                  class="ml-3 mb-1"
                  type="is-danger is-light"
                >
                  Coming soon
                </b-tag>
              </h5>
              <p>{{ type.info }}</p>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="setNewModuleModalVisibility(false)"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="createModule()"
            >
              Create
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
// libs
import { ref, reactive, onMounted, watch } from '@vue/composition-api';
import * as Yup from 'yup';
// components
import ModuleList from '@/modules/builder/components/application/ModuleList';
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useApplicationStore } from '@/modules/builder/store/applicationStore';
import { useFormBuilderStore } from '@/modules/builder/store/formBuilderStore';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// services
import { fetchApplicationByIdService } from '@/services/application-service/applicationRequests';
// composables
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { refResetter } from '@/hooks/utils';
// others
import { generateNode } from '@/modules/core/components/generics/base-tree-flow/treeFlowUtils';

// use composables
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const router = useRouter();
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const applicationStore = useApplicationStore();
  const formBuilderStore = useFormBuilderStore();

  //-- application page logic --//
  const options = reactive({
    types: [{
      id: 1,
      name: 'Web app',
      icon: 'desktop-mac',
      info: 'Create responsive web application with smart forms and user interface that represent your brand.'
    }, {
      id: 4,
      name: 'Mobile app',
      icon: 'cellphone',
      info: 'Create mobile application and publish it on Google Play and Apple Store.'
    }, {
      id: 2,
      name: 'API',
      icon: 'api',
      info: 'Create your own RESTful API or connect to external and/or internal APIs in server to server application.'
    }, {
      id: 3,
      name: 'Scheduled job',
      icon: 'clock',
      info: 'Create scheduled jobs and workflows that run and execute in a specific time.'
    }]
  });

  // -- Module Validation --//
  const ModuleSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.').min(2, 'Name must be at least 2 characters long.')
  });
  const moduleErrors = reactive({
    name: ''
  });
  const validateModule = async field => {
    try {
      await ModuleSchema.validateAt(field, module.value);
      moduleErrors[field] = '';
    } catch (err) {
      moduleErrors[err.path] = err.message;
    }
  };
  const fetchApplication = async () => {
    try {
      await fetchApplicationByIdService(route.params.appId);
    } catch (err) {
      console.error(err);
    }
  };
  onMounted(() => {
    fetchApplication();
  });
  const selectType = (type, id) => {
    if (id === 4) return;
    module.value.type = type;
    module.value.id = id;
  };

  //-- module modal logic --//
  const isNewModuleModalActive = ref(false);
  const setNewModuleModalVisibility = visibility => {
    isNewModuleModalActive.value = visibility;
  };
  watch(() => isNewModuleModalActive.value, () => {
    if (!isNewModuleModalActive.value) {
      resetModule();
    }
  });

  //-- create module logic --//
  const isModuleCreationFailed = ref(false);
  const [module, resetModule] = refResetter({
    name: '',
    type: '',
    id: null
  });
  const createModule = async () => {
    try {
      await ModuleSchema.validate(module.value, {
        abortEarly: false
      });
    } catch (err) {
      err.inner.reverse().forEach(error => {
        moduleErrors[error.path] = error.message;
      });
      return false;
    }
    try {
      const {
        appId
      } = route.params;
      if (module.value.type === '') {
        isModuleCreationFailed.value = true;
        return false;
      }
      isModuleCreationFailed.value = false;
      applicationStore.reset();
      formBuilderStore.reset();
      applicationStore.setName(module.value.name);
      const nodeData = {
        type: 'start',
        data: {
          name: 'Start'
        }
      };
      const newNode = generateNode([], nodeData);
      const moduleId = await moduleStore.createModule(appId, {
        name: module.value.name,
        type_id: module.value.id,
        data: [newNode]
      });
      const newNodeInfo = {
        type: 'Always',
        name: 'Start'
      };
      await moduleGraphStore.addNodeInfo(appId, moduleId, newNode.nodeId, newNodeInfo);
      setNewModuleModalVisibility(false);
      router.push('/application/' + appId + '/module/' + moduleId + '/edit');
    } catch (err) {
      console.error(err);
    }
  };
  return {
    options,
    moduleErrors,
    validateModule,
    selectType,
    isNewModuleModalActive,
    setNewModuleModalVisibility,
    isModuleCreationFailed,
    module,
    createModule
  };
};
__sfc_main.components = Object.assign({
  ModuleList,
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';

.module-type-selected-card {
  box-shadow: 0 0.5em 1em -0.125em #EEEEFF, 0 0px 0 1px #EEEEFF;
  color: $primary;
}

.selection-list {
  cursor: pointer;
}

.selection-list:hover {
  p {
    color: $primary;
  }
  h5 {
    color: $primary;
  }
  .icon {
    color: $primary;
  }
}

.disabled, .disabled:hover {
  cursor: default;
  p {
    color: #dedede;
  }
  h5 {
    color: #dedede;
  }
  .icon {
    color: #dedede;
  }
}

.selected {
  p {
    color: $dark;
  }
  h5 {
    color: $dark;
  }
  .icon {
    color: $dark;
  }
}

.unselected {
  p {
    color: #dedede;
  }
  h5 {
    color: #dedede;
  }
  .icon {
    color: #dedede;
  }
}
</style>