var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"columns is-vcentered px-5"},[_c('div',{staticClass:"is-10 column"},[_c('b-skeleton',{attrs:{"height":"30"}}),_c('b-skeleton',{attrs:{"height":"50"}})],1),_c('div',{staticClass:"is-2 column"},[_c('b-skeleton',{attrs:{"height":"30"}})],1)]):(_vm.tableData.length === 0)?_c('NoResults',{attrs:{"banner-label":_vm.noResultsContent.bannerLabel,"button-label":_vm.noResultsContent.buttonLabel,"button-handler":function () { return _vm.handleTableAction(_vm.tableActions.CREATE); }}}):_c('b-table',{staticClass:"tab-table-layout",attrs:{"data":_vm.tableData},on:{"click":function($event){return _vm.handleTableAction(_vm.tableActions.EDIT, $event)}}},[_c('b-table-column',{attrs:{"label":"Name","field":"name","width":"30%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Modified","field":"updated_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.updated_at)+" "),(row.last_editor)?_c('b-tooltip',{attrs:{"label":row.last_editor}},[_c('b-button',{staticClass:"button disk disk-small is-primary",attrs:{"size":"is-small"}},[_vm._v(" "+_vm._s(row.last_editor_initials)+" ")])],1):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"row.test_version","label":"TEST"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":"Version deployed and running on test","append-to-body":"","dashed":""}},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.test_version)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Production","field":"prod_deployed_version"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":"Version deployed and running on production","append-to-body":"","dashed":""}},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.prod_version)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Type","field":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.type)+" "),_c('b-icon',{staticClass:"app-dropdown-icon",attrs:{"icon":_vm.iconMapper(row),"size":"is-size-6"}})]}}])}),_c('b-table-column',{attrs:{"label":"","cell-class":"has-text-right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button',{staticClass:"app-dropdown-btn",attrs:{"size":"is-small"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleOptionsDropdown(row)}}},[_c('b-icon',{staticClass:"app-dropdown-icon",attrs:{"icon":"dots-horizontal","size":"is-size-6"}})],1),(row.dropdown.isLoaded)?_c('ModuleOptionsDropdown',{attrs:{"module-info":row},on:{"select":function($event){return _vm.handleTableAction($event, row)}},model:{value:(row.dropdown.isVisible),callback:function ($$v) {_vm.$set(row.dropdown, "isVisible", $$v)},expression:"row.dropdown.isVisible"}}):_vm._e()]}}])})],1),_c('ModuleInfoSidebar',{attrs:{"module-info":_vm.sidebarInfo},on:{"guard-module":function($event){return _vm.updateGuardStatus($event, 'guard')},"unguard-module":function($event){return _vm.updateGuardStatus($event, 'unguard')}},model:{value:(_vm.isSidebarVisible),callback:function ($$v) {_vm.isSidebarVisible=$$v},expression:"isSidebarVisible"}}),_c('DeleteModuleModal',{attrs:{"module-info":_vm.moduleToBeDeleted},on:{"delete":_vm.deleteModule},model:{value:(_vm.isDeleteModuleModalVisible),callback:function ($$v) {_vm.isDeleteModuleModalVisible=$$v},expression:"isDeleteModuleModalVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }