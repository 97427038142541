// @ts-check
import { WORKFLOW_RESOURCE_PATH } from './applicationServiceConstants';
import { moduleServiceConfig } from './moduleRequests';

export const workflowServiceConfig = {
    ...moduleServiceConfig,
    /**
     * @param {string} applicationId
     * @param {string} moduleId
     */
    getWorkflowResourcePath: (applicationId, moduleId) => `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${WORKFLOW_RESOURCE_PATH}`
};

/**
 * @param {string} applicationId
 * @param {string} moduleId
 */
export const fetchWorkflowsService = (applicationId, moduleId) => workflowServiceConfig.http({
    method: 'GET',
    url: workflowServiceConfig.getWorkflowResourcePath(applicationId, moduleId)
});
